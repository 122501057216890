import { handleHttpError, baseUrl, getToken } from "./utils";

const axios = require("axios").default;

async function fetchAllUserNotifications(user_id) {
  try {
    const token = await getToken();

    const response = await axios.get(
      `${baseUrl}/notifications/user/${user_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data.data;
  } catch (err) {
    const message = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred fetching Notifications. Please try again or contact the System Admin";
    return handleHttpError(message);
  }
}

async function fetchNotification(notificationId) {
  try {
    const token = await getToken();

    const response = await axios.get(
      `${baseUrl}/notifications/${notificationId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data.data;
  } catch (err) {
    const message = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred fetching Notification. Please try again or contact the System Admin";
    return handleHttpError(message);
  }
}

export { fetchAllUserNotifications, fetchNotification };
