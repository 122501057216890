export default {
  setBriefs(state, payLoad) {
    return (state.briefs = payLoad);
  },
  setBriefsCart(state, payLoad) {
    return (state.briefsCart = payLoad);
  },
  setCurrentBrief(state, payLoad) {
    return (state.currentBrief = payLoad);
  },
  setBriefCampaigns(state, payLoad) {
    return (state.briefCampaigns = payLoad);
  },
};
