import Vue from "vue";
import Vuex from "vuex";

import billboardsModule from "./modules/billboards";
import authModule from "./modules/auth";
import poiModule from "./modules/poi";
import briefsModule from "./modules/briefs";
import regionsModule from "./modules/regions";
import campaignModule from "./modules/campaign";
import userModule from "./modules/user";
import notificationsModule from "./modules/notifications";
import MonitoringModule from "./modules/monitoring";
import brandsModule from "./modules/brands";

import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser: null,
  },
  getters,
  mutations,
  actions,
  modules: {
    billboards: billboardsModule,
    auth: authModule,
    pois: poiModule,
    briefs: briefsModule,
    regions: regionsModule,
    campaigns: campaignModule,
    user: userModule,
    notifications: notificationsModule,
    monitoring: MonitoringModule,
    brands: brandsModule,
  },
});
