import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#00adee",
        secondary: "#8457a6",
        accent: "#f16f2c",
        error: "#EC1C24",
        info: "#131c26",
        success: "#2E7D32",
        warning: "#ee7b10",
        background: "#F4F6F8",
        cardsBackground: "#ffffff",
        actionButton: "f68625",
        iconFill: "#84818A",
        expansionPanels: "#ceffff",
        textGray: "#a6a8ab",
        white: "#ffffff",
      },
      dark: {
        primary: "#081623",
        secondary: "#000000",
        accent: "#c97e5b",
      },
    },
  },
});
