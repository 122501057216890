export default {
  getBrandCompetitors(state) {
    return state.brandCompetitors;
  },
  getUserBrands(state) {
    return state.userBrands;
  },
  getAllBrands(state) {
    return state.allBrands;
  },
};
