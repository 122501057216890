export default {
  getRegions(state) {
    return state.regions;
  },
  getRoadsList(state) {
    return state.roadsList;
  },
  getRoadsData(state) {
    return state.roadsData;
  },
};
