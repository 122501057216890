import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
  namespaced: true,
  state() {
    return {
      userDetails: {
        firstName: null,
        lastName: null,
        designation: null,
        email: null,
        userType: null,
        Organization: {
          fullName: null,
          address: null,
        },
      },
    };
  },
  mutations,
  getters,
  actions,
};
