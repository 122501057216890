import { handleHttpError, baseUrl, getToken, reportSuccess } from "./utils";
const axios = require("axios").default;
import { removeFromLF } from "../store/storage/localForage";

async function fetchUserDetails() {
  const token = await getToken();
  try {
    const response = await axios.get(`${baseUrl}/users`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (err) {
    const message = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred fetching user details. Please try again or contact the system admin";

    return handleHttpError(message);
  }
}

async function forgotPassword({ email }) {
  try {
    const response = await axios.post(`${baseUrl}/users/password-reset`, {
      email,
    });
    if (response.status === 200) return true;

    // sth went wrong
    const error =
      "An error occurred resetting password. Please try again or contact the system admin";
    handleHttpError(error);
    return false;
  } catch (err) {
    const message = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred resetting password. Please try again or contact the system admin";

    handleHttpError(message);
    return false;
  }
}

async function resetPassword(payload) {
  try {
    const response = await axios.post(
      `${baseUrl}/users/set-new-password/${payload.token}`,
      {
        password: payload.password,
      }
    );
    if (response.status === 200) return true;
    return false;
  } catch (err) {
    const message = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred saving new password. Please try again or contact the system admin";

    handleHttpError(message);
    return false;
  }
}

async function verifyAccount(payload) {
  try {
    const response = await axios.post(
      `${baseUrl}/users/verify-account/${payload.token}`
    );
    return response.status === 200;
  } catch (err) {
    const message = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred verifying account. Please try again or contact the system admin";

    handleHttpError(message);
    return false;
  }
}

async function rejectInvite(payload) {
  try {
    const response = await axios.post(
      `${baseUrl}/users/revoke-invitation/${payload.invite_token}`
    );
    return response.status === 200;
  } catch (err) {
    const message = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred rejecting invite. Please try again or contact the system admin";

    handleHttpError(message);
    return false;
  }
}

async function inviteUser(payload) {
  try {
    const token = await getToken();

    const response = await axios.post(`${baseUrl}/users/invite-user`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      reportSuccess("Invitation sent");
    }
  } catch (err) {
    const error = err.response.data
      ? ` ${err.response.data.error.message}`
      : "An error occurred inviting user. Please try again or contact the system admin";

    handleHttpError(error);
    return false;
  }
}

async function deleteUserAccount(user_id) {
  try {
    const token = await getToken();

    const response = await axios.delete(`${baseUrl}/users/${user_id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 204) {
      reportSuccess("Account deleted");
      await removeFromLF("token");
      await removeFromLF("user");
    }
  } catch (err) {
    const message = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred deleting account. Please try again or contact the system admin";
    handleHttpError(message);
  }
}

async function fetchOrganizationProfile(userId) {
  try {
    const token = await getToken();

    const response = await axios.get(
      `${baseUrl}/users/${userId}/organization-profile`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data.data;
  } catch (err) {
    const message = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred fetching Organization Profile. Please try again or contact the System Admin";
    return handleHttpError(message);
  }
}

export {
  fetchUserDetails,
  forgotPassword,
  resetPassword,
  verifyAccount,
  rejectInvite,
  deleteUserAccount,
  fetchOrganizationProfile,
  inviteUser,
};
