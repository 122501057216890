import {
  fetchAllUserNotifications,
  fetchNotification,
} from "@/helpers/notifications";

export default {
  async fetchAllUserNotifications({ commit }, payload) {
    const notifications = await fetchAllUserNotifications(payload);
    return commit("setNotifications", notifications);
  },

  async fetchNotification(_, payLoad) {
    return await fetchNotification(payLoad);
  },
};
