<template>
  <v-snackbar
    v-model="snackbar"
    :color="status"
    :bottom="y === 'bottom'"
    :left="x === 'left'"
    :right="x === 'right'"
    :top="y === 'top'"
    :timeout="6000"
    multi-line
    rounded
  >
    <span v-html="message" />
  </v-snackbar>
</template>

<script>
import EventBus from "@/components/events/EventBus";

export default {
  data() {
    return {
      snackbar: false,
      status: null,
      message: "",
      x: null,
      y: "top",
    };
  },
  mounted() {
    EventBus.$on("alert", (params) => {
      this.alert(params);
    });
  },
  methods: {
    alert({ message, status = "info", x = "right", y = "bottom" }) {
      this.message = message;
      this.status = status;
      this.snackbar = true;
      this.x = x;
      this.y = y;
    },
  },
};
</script>

<style lang="scss" scoped></style>
