import { handleHttpError, reportSuccess, baseUrl, getToken } from "./utils";
const axios = require("axios").default;

async function fetchCandidates(payload) {
  try {
    const token = await getToken();
    const response = await axios.get(`${baseUrl}/candidates/${payload.token}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (err) {
    const message = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred fetching. Please try again or contact the system admin";

    handleHttpError(message);
  }
}

async function mediaOwnerBidCampaign(data) {
  const token = await getToken();
  try {
    const response = await axios.post(`${baseUrl}/candidates/owner`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status == 204) {
      reportSuccess(
        `Campaign has been bid successfully. You will be redirected.`
      );
      return true;
    }
  } catch (err) {
    const message = err.response.data
      ? `${err.response.data.error.message}`
      : "An error was encountered trying to upload the billboard. Please try again or Contact the System Admin";
    return handleHttpError(message);
  }
}
async function updateCandidates(payload) {
  const token = await getToken();
  try {
    const response = await axios.patch(
      `${baseUrl}/candidates/${payload.token}`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (response.status == 204) {
      reportSuccess(
        `Update Billboard availability successfully. You will be redirected.`
      );
      return true;
    }
  } catch (err) {
    const message = err.response.data
      ? `${err.response.data.error.message}`
      : "An error was encountered trying to update the billboard. Please try again or Contact the System Admin";
    return handleHttpError(message);
  }
}

async function removeCandidate(id) {
  const token = await getToken();
  try {
    await axios.delete(`${baseUrl}/candidates/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const message = `Billboard deleted successfully`;
    return reportSuccess(message);
  } catch (err) {
    const message = err.response.data
      ? `${err.response.data.error.message}`
      : "An error was encountered trying to remove the billboard. Please try again or Contact the System Admin";
    return handleHttpError(message);
  }
}

export {
  fetchCandidates,
  updateCandidates,
  removeCandidate,
  mediaOwnerBidCampaign,
};
