import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
  namespaced: true,
  state() {
    return {
      // bb in general
      billboards: [],
      // pop within the bb area
      population: null,
      district_spending: null,
      sub_county_spending: null,
      // bbs in cart
      bbCart: [],
      // within the users area
      within: [],
      // search results
      search: [],
      // brand names
      brands: [],

      owners: [],
      candidates: [
        {
          candidates: [
            {
              end_date: null,
              start_date: null,
              available: null,
              available_on: null,
              marketerSelected: null,
              plannerSelected: null,
              Billboard: {
                id: null,
                latitude: null,
                longitude: null,
                address: null,
                Images: [],
                lighting: null,
                faces: null,
                clutter: null,
              },
            },
          ],
          plan: {
            name: null,
            Planner: {
              User: {
                firstName: null,
                lastName: null,
                Organization: {
                  fullName: null,
                },
              },
            },
            Brief: {
              region: [],
              sec: [],
              preferred_medium_type: [],
              BrandOwner: {
                Brand: {
                  name: null,
                },
              },
            },
          },
        },
      ],
      bookedBBs: [],
      availableBBs: [],
      soonAvailableBBs: [],
      contractExpiredBBs: [],
    };
  },
  mutations,
  getters,
  actions,
};
