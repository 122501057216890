export default {
  setBrandCompetitors(state, payLoad) {
    state.brandCompetitors = payLoad;
  },
  setUserBrands(state, payLoad) {
    state.userBrands = payLoad;
  },
  setAllBrands(state, payLoad) {
    state.allBrands = payLoad;
  },
};
