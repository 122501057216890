export default {
  getBriefs(state) {
    return state.briefs;
  },
  getBriefsCart(state) {
    return state.briefsCart;
  },
  getCurrentBrief(state) {
    return state.currentBrief;
  },
  getBriefCampaigns(state) {
    return state.briefCampaigns;
  },
};
