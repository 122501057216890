import {
  fetchUserDetails,
  forgotPassword,
  resetPassword,
  verifyAccount,
} from "@/helpers/user";

export default {
  async fetchUserDetails({ commit }) {
    const res = await fetchUserDetails();
    return commit("setUserDetails", res);
  },
  async forgotPassword(_, payload) {
    return await forgotPassword(payload);
  },
  async resetPassword(_, payload) {
    return await resetPassword(payload);
  },
  async verifyAccount(_, payload) {
    return await verifyAccount(payload);
  },
};
