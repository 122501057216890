import { handleHttpError, reportSuccess, baseUrl, getToken } from "./utils";
import {
  getFromLF,
  savetoLF,
  removeFromLF,
} from "../store/storage/localForage";

const axios = require("axios").default;

async function fetchAllBbs() {
  const token = await getToken();
  try {
    const response = await axios.get(`${baseUrl}/billboards`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data.data.billboards;
  } catch (err) {
    const error = err.response.data
      ? `Error fetching Billboards: ${err.response.data.error.message}`
      : "An error occurred fetching Billboards. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchMyBbs(user_id) {
  const token = await getToken();
  try {
    const response = await axios.get(`${baseUrl}/billboards/user/${user_id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const billboards = response.data.data;

    await removeFromLF("billboards");

    await savetoLF("billboards", billboards);

    return billboards;
  } catch (err) {
    const error = err.response.data
      ? `Error fetching Billboards: ${err.response.data.error.message}`
      : "An error occurred fetching Billboards. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchBriefBbs(brief_id) {
  const token = await getToken();
  try {
    const response = await axios.get(
      `${baseUrl}/billboards/?briefId=${brief_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data.data;
  } catch (err) {
    const error = err.response.data
      ? `Error fetching Billboards: ${err.response.data.error.message}`
      : "An error occurred fetching Billboards. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchBB(id) {
  const token = await getToken();
  try {
    const response = await axios.get(`${baseUrl}/billboards/?id=${+id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (err) {
    const error = err.response.data
      ? `Error fetching Billboard: ${err.response.data.message}`
      : "An error occurred fetching Billboard. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchBbsByBbox({ xmin, ymin, xmax, ymax }) {
  // make api call
  // check if there is data in the local storage
  // get billboards with distinct ids
  //  replace them from ls
  const token = await getToken();

  try {
    const response = await axios.get(`${baseUrl}/billboards/bbox`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { xmin, ymin, xmax, ymax },
    });

    const billboards = response.data.data;

    return billboards;
  } catch (err) {
    const error = err.response.data
      ? `Error fetching Billboards: ${err.response.data.error.message}`
      : "An error occurred fetching Billboards. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchPop({ long, lat }) {
  const token = await getToken();

  try {
    const response = await axios.get(`${baseUrl}/data/population`, {
      params: { long, lat },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.data.data) {
      const error = `Population data not found`;
      return handleHttpError(error);
    }
    return response.data.data;
  } catch (err) {
    const error = err.response.data
      ? `Error fetching Population: ${err.response.data.error.message}`
      : "An error occurred fetching Population. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchDistrictSpending({ long, lat }) {
  const token = await getToken();

  try {
    const response = await axios.get(`${baseUrl}/data/district-spending`, {
      params: { long, lat },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.data.data) {
      const error = `District Spending data not found`;
      return handleHttpError(error);
    }
    return response.data.data;
  } catch (err) {
    const error = err.response.data
      ? `Error fetching District Spending: ${err.response.data.error.message}`
      : "An error occurred fetching District Spending. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchSubCountySpending({ long, lat }) {
  const token = await getToken();

  try {
    const response = await axios.get(`${baseUrl}/data/subcounty-spending`, {
      params: { long, lat },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.data.data) {
      const error = `SubCounty Spending data not found`;
      return handleHttpError(error);
    }
    return response.data.data;
  } catch (err) {
    const error = err.response.data
      ? `Error fetching SubCounty Spending: ${err.response.data.error.message.message}`
      : "An error occurred fetching SubCounty Spending. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function searchBillboards(payLoad) {
  try {
    const token = await getToken();
    const response = await axios.post(`${baseUrl}/billboards/search`, payLoad, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data.data;
  } catch (err) {
    const error = err.response.data
      ? `: ${err.response.data.error.message}`
      : "An error occurred searching billboards. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchBBPaginated(page) {
  const token = await getToken();

  try {
    // should check ls
    const response = await axios.get(`${baseUrl}/billboards/pages/${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const billboards = response.data.data;

    // should save to ls

    return billboards;
  } catch (err) {
    const error = err.response.data
      ? `: ${err.response.data.error.message}`
      : "An error occurred fetching Billboards. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function addBillboard(payload) {
  const token = await getToken();
  try {
    await axios.post(`${baseUrl}/billboards`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const message = `Added Billboard successfully`;
    return reportSuccess(message);
  } catch (err) {
    const message = err.response.data
      ? `: ${err.response.data.error.message}`
      : "An error was encountered trying to upload the billboard. Please try again or Contact the System Admin";
    return handleHttpError(message);
  }
}

async function updateBillboard(payload) {
  const token = await getToken();
  try {
    await axios.put(`${baseUrl}/billboards/${payload.id}`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const message = `Updated Billboard successfully`;
    return reportSuccess(message);
  } catch (err) {
    const message = err.response.data
      ? `: ${err.response.data.error.message}`
      : "An error was encountered trying to update the billboard. Please try again or Contact the System Admin";
    return handleHttpError(message);
  }
}

async function setBillboardAvailability(payload) {
  const token = await getToken();
  let requestData = { status: payload.status };
  try {
    await axios.patch(`${baseUrl}/billboards/code/${payload.id}`, requestData, {
      headers: { Authorization: `Bearer ${token}` },
    });

    reportSuccess("Billboard availability updated successfully");
  } catch (err) {
    const message = err.response.data
      ? `Error Updating Billboard Availability: ${err.response.data.error.message.message}`
      : "An error was encountered trying to update billboard availability. Please try again or Contact the System Admin";
    return handleHttpError(message);
  }
}

async function deleteBB(id) {
  const token = await getToken();
  try {
    await axios.delete(`${baseUrl}/billboards/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const message = `Billboard deleted successfully`;
    return reportSuccess(message);
  } catch (err) {
    const error = err.response.data
      ? `: ${err.response.data.error.message}`
      : "An error occurred Deleting Billboard.Please try again or Contact the System Admin ";
    return handleHttpError(error);
  }
}

async function addBbToCart(id) {
  // add id to the bb cart[]
  let bbCart = await getFromLF("bbCart");
  if (bbCart) {
    if (bbCart.indexOf(id) === -1) {
      bbCart.push(id);
    }
    await savetoLF("bbCart", bbCart);
  } else {
    bbCart = [id];
    await savetoLF("bbCart", bbCart);
  }
  return bbCart;
}

async function fetchBbCart() {
  let bbCart = await getFromLF("bbCart");
  if (bbCart) return bbCart;
  return [];
}

async function removeBbFromCart(bbId) {
  let bbCart = await getFromLF("bbCart");

  if (!bbCart) {
    handleHttpError("The cart is currently empty");
    return [];
  }

  let idx = bbCart.indexOf(bbId);
  if (idx == -1) {
    handleHttpError("The billboard is not in the cart currently");
    return bbCart;
  } else {
    bbCart = bbCart.filter((id) => id !== bbId);

    await savetoLF("bbCart", bbCart);
    reportSuccess("Billboard removed from cart successfully");
    return bbCart;
  }
}

async function fetchBrands() {
  try {
    const token = await getToken();
    const response = await axios.get(`${baseUrl}/billboards/brands`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const brands = new Set();
    response.data.data[0].brands.forEach((br) => {
      if (br) {
        brands.add(br);
      }
    });

    return [...brands];
  } catch (err) {
    const error = err.response.data
      ? `: ${err.response.data.error.message}`
      : "An error occured fetching brands. Please try again or Contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchOwners() {
  try {
    const token = await getToken();
    const response = await axios.get(`${baseUrl}/billboards/owners`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const owners = new Set();
    response.data.data[0].owners.forEach((suppl) => {
      if (suppl) {
        owners.add(suppl);
      }
    });
    return [...owners];
  } catch (err) {
    const error = err.response.data
      ? ` : ${err.response.data.error.message}`
      : "An error occurred fetching owners. Please try again or Contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchBookedBillboards(user_id) {
  try {
    const token = await getToken();

    const response = await axios.get(
      `${baseUrl}/billboards/user/${user_id}/booked`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data.data.booked_billboards;
  } catch (err) {
    const error = err.response.data
      ? `: ${err.response.data.error.message}`
      : "An error occurred fetching booked billboards. Please try again or Contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchSoonToBeAvailableBillboards(user_id) {
  try {
    const token = await getToken();

    const response = await axios.get(
      `${baseUrl}/billboards/user/${user_id}/soon-available`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data.data.soon_available_billboards;
  } catch (err) {
    const error = err.response.data
      ? `: ${err.response.data.error.message}`
      : "An error occurred fetching booked billboards. Please try again or Contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchAvailableBillboards(user_id) {
  try {
    const token = await getToken();

    const response = await axios.get(
      `${baseUrl}/billboards/user/${user_id}/available`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data.data.available_billboards;
  } catch (err) {
    const error = err.response.data
      ? `: ${err.response.data.error.message}`
      : "An error occurred fetching booked billboards. Please try again or Contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchExpiredContractBillboards(user_id) {
  try {
    const token = await getToken();

    const response = await axios.get(
      `${baseUrl}/billboards/user/${user_id}/contract-expired`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data.data.contract_expired;
  } catch (err) {
    const error = err.response.data
      ? `: ${err.response.data.error.message}`
      : "An error occurred fetching booked billboards. Please try again or Contact the System Admin";
    return handleHttpError(error);
  }
}

export {
  fetchBB,
  fetchPop,
  fetchAllBbs,
  fetchBbsByBbox,
  addBillboard,
  updateBillboard,
  fetchBBPaginated,
  deleteBB,
  addBbToCart,
  fetchBbCart,
  removeBbFromCart,
  searchBillboards,
  fetchBrands,
  fetchOwners,
  fetchMyBbs,
  fetchBriefBbs,
  fetchDistrictSpending,
  fetchSubCountySpending,
  setBillboardAvailability,
  fetchBookedBillboards,
  fetchAvailableBillboards,
  fetchExpiredContractBillboards,
  fetchSoonToBeAvailableBillboards,
};
