import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
  namespaced: true,
  state() {
    return {
      allBrands: [
        {
          name: null,
          id: null,
          BrandCategory: {
            id: null,
            name: null,
          },
          BrandOwners: [
            {
              Briefs: [
                {
                  id: null,
                  Plans: [
                    {
                      id: null,
                    },
                  ],
                },
              ],
              Marketer: { id: null },
              id: null,
            },
          ],
        },
      ],
      brandCompetitors: [],
      userBrands: [
        {
          name: null,
          id: null,
          BrandCategory: {
            id: null,
            name: null,
          },
          BrandOwners: [
            {
              Briefs: [
                {
                  id: null,
                  Plans: [
                    {
                      id: null,
                    },
                  ],
                },
              ],
              Marketer: { id: null },
              id: null,
            },
          ],
        },
      ],
    };
  },
  mutations,
  getters,
  actions,
};
