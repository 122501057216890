import { login, logout, register, tryLogin } from "@/helpers/auth";

export default {
  autoLogout(context) {
    context.dispatch("logout");
    context.commit("didAutoLogout");
  },

  async logout({ commit }) {
    await logout();
    commit("setCurrentUser", null, { root: true });
  },

  async login({ commit }, payLoad) {
    const res = await login(payLoad);

    if (!res.user) return { error: res.error };
    commit("setCurrentUser", res.user, { root: true });

    return { user: res.user };
  },

  async register({ commit }, payLoad) {
    const res = await register(payLoad);
    if (!res.user) return { error: res.error };
    commit("setCurrentUser", res.user, { root: true });

    return { user: res.user };
  },
  async tryLogin({ commit }) {
    const res = await tryLogin();

    commit("setCurrentUser", null, { root: true });
    if (res?.token) {
      commit("setCurrentUser", res.user, { root: true });
    } else {
      commit("setCurrentUser", null, { root: true });
    }
  },
};
