import { handleHttpError, reportSuccess, baseUrl, getToken } from "./utils";

const axios = require("axios").default;

async function fetchMonitoringByDate(date) {
  try {
    const token = await getToken();

    const response = await axios.get(`${baseUrl}/monitor/?date=${date}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (err) {
    const error = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred fetching Monitoring. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchMonitoringByQuery(query) {
  try {
    const token = await getToken();

    const response = await axios.get(`${baseUrl}/monitor/?${query}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (err) {
    const error = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred fetching Monitoring. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchMonitoringByDates({ start_date, end_date }) {
  try {
    const token = await getToken();

    const response = await axios.get(
      `${baseUrl}/monitor/?start_date=${start_date}&end_date=${end_date}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data.data;
  } catch (err) {
    const error = err.response.data
      ? ` ${err.response.data.error.message}`
      : "An error occurred fetching Monitoring. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchMonitoringByCampaign(planId) {
  try {
    const token = await getToken();

    const response = await axios.get(`${baseUrl}/monitor/?planId=${planId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data.data;
  } catch (err) {
    const error = err.response.data
      ? ` ${err.response.data.error.message}`
      : "An error occurred fetching Monitoring. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchMonitoringByMonitor(monitorId) {
  try {
    const token = await getToken();

    const response = await axios.get(
      `${baseUrl}/monitor/?monitorId=${monitorId}}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data.data;
  } catch (err) {
    const error = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred fetching Monitoring. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchMonitoringByCandidate(candidateId) {
  try {
    const token = await getToken();

    const response = await axios.get(
      `${baseUrl}/monitor/?candidateId=${candidateId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data.data;
  } catch (err) {
    const error = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred fetching Monitoring. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchAllMonitoring() {
  try {
    const token = await getToken();

    const response = await axios.get(`${baseUrl}/monitor}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data.data;
  } catch (err) {
    const error = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred fetching Monitoring. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function monitorByOwner(payLoad) {
  const token = await getToken();
  try {
    await axios.post(`${baseUrl}/monitor/owner`, payLoad, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const message = "Monitoring added successfully";
    return reportSuccess(message);
  } catch (err) {
    const message = err.response.data
      ? `${err.response.data.error.message}`
      : "An error was encountered trying to upload the Monitoring. Please try again or Contact the System Admin";
    return handleHttpError(message);
  }
}

export {
  fetchAllMonitoring,
  fetchMonitoringByCandidate,
  fetchMonitoringByDate,
  fetchMonitoringByDates,
  fetchMonitoringByMonitor,
  fetchMonitoringByCampaign,
  monitorByOwner,
  fetchMonitoringByQuery,
};
