import mutations from "./mutations.js";
import getters from "./getters.js";
import actions from "./actions.js";

export default {
  namespaced: true,
  state() {
    return {
      username: null,
      token: null,
      exp: null,
      autoLogout: false,
    };
  },
  mutations,
  getters,
  actions,
};
