export default {
  setBillboards(state, payLoad) {
    return (state.billboards = payLoad);
  },
  setPopulation(state, payLoad) {
    return (state.population = payLoad);
  },
  setDistrictSpending(state, payLoad) {
    return (state.district_spending = payLoad);
  },
  setSubCountySpending(state, payLoad) {
    return (state.sub_county_spending = payLoad);
  },
  setBBToCart(state, payLoad) {
    return (state.bbCart = payLoad);
  },
  setSearchResults(state, payLoad) {
    return (state.search = payLoad);
  },
  setBrands(state, payLoad) {
    return (state.brands = payLoad);
  },
  setOwners(state, payLoad) {
    return (state.owners = payLoad);
  },
  setCandidates(state, payLoad) {
    return (state.candidates = payLoad);
  },
  setBookedBBs(state, payLoad) {
    return (state.bookedBBs = payLoad);
  },
  setAvailableBBs(state, payLoad) {
    return (state.availableBBs = payLoad);
  },
  setSoonAvailableBBs(state, payLoad) {
    return (state.soonAvailableBBs = payLoad);
  },
  setContractExpiredBBs(state, payLoad) {
    return (state.contractExpiredBBs = payLoad);
  },
};
