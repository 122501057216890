import Vue from "vue";
import VueRouter from "vue-router";

import AuthRequired from "./guards/auth_required";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/insights",
    component: () => import(/* webpackChunkName: "main" */ "@/pages"),
    children: [
      {
        path: "/insights",
        name: "Insights",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "insights" */ "@/pages/insights/MarketingInsights.vue"
          ),
        meta: {
          resource: "insights",
        },
      },
      {
        path: "/briefs",
        name: "Brief",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "briefs" */ "@/pages/briefs/TheBrief.vue"
          ),
        children: [
          {
            path: "/list",
            component: () =>
              import(
                /* webpackChunkName: "briefs-list" */ "@/components/briefs/BriefsList.vue"
              ),
          },
          {
            path: "/create",
            component: () =>
              import(
                /* webpackChunkName: "create-brief" */ "@/components/briefs/CreateBrief.vue"
              ),
          },
        ],
        meta: {
          resource: "briefs",
        },
      },
      {
        path: "/monitoring",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "monitoring" */ "@/pages/monitoring/BillboardsMonitoring.vue"
          ),
        meta: {
          resource: "monitoring",
        },
      },
      {
        path: "/campaigns",
        name: "Campaign",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "campaigns" */ "@/pages/campaign/TheCampaign.vue"
          ),
        meta: {
          resource: "campaigns",
        },
      },
      {
        path: "/owner-campaigns",
        name: "Campaigns",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "campaigns" */ "@/pages/campaign/TheOwnerCampaign.vue"
          ),
        meta: {
          resource: "owner-campaigns",
        },
      },
      {
        path: "/proposed-campaigns",
        name: "Proposed-Campaigns",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "proposed-campaigns" */ "@/pages/campaign/ProposedCampaigns.vue"
          ),
        meta: {
          resource: "proposed-campaigns",
        },
      },
      {
        path: "/proposed-campaign/:campaignToken",
        name: "Proposed-Campaign",
        component: () =>
          import(
            /* webpackChunkName: "proposed-campaign" */ "@/components/campaign/ProposedCampaign.vue"
          ),
        meta: {
          resource: "proposed-campaign",
        },
      },
      {
        path: "/cart",
        name: "Cart",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "cart" */ "@/pages/billboards/BillboardCart.vue"
          ),
        meta: {
          resource: "cart",
        },
      },
      {
        path: "/editBBs",
        name: "EditBillboards",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "editBBs" */ "@/components/billboard/BillboardList.vue"
          ),
        meta: {
          resource: "editBBs",
        },
      },
      {
        path: "/billboards",
        name: "Billboards",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "billboards" */ "@/pages/media_owner/MediaOwner.vue"
          ),
        children: [
          {
            path: "/add-billboard",
            name: "BillboardForm",
            component: () =>
              import(
                /* webpackChunkName: "add-billboard" */ "@/components/billboard/BillboardForm.vue"
              ),
          },
          {
            path: "/all",
            name: "AllBillboards",
            component: () =>
              import(
                /* webpackChunkName: "all-billboards" */ "@/components/media_owner/AllBillboards.vue"
              ),
          },
          {
            path: "/soon-to-be-available",
            name: "SoonavailableBillboards",
            component: () =>
              import(
                /* webpackChunkName: "soon-to-be-available-billboards" */ "@/components/media_owner/SoonavailableBillboards.vue"
              ),
          },
          {
            path: "/contact-expired",
            name: "ContractexpiredBillboards",
            component: () =>
              import(
                /* webpackChunkName: "contract-expired-billboards" */ "@/components/media_owner/ContractexpiredBillboards.vue"
              ),
          },
          {
            path: "/available",
            name: "AvailableBillboards",
            component: () =>
              import(
                /* webpackChunkName: "contract-billboards" */ "@/components/media_owner/AvailableBillboards.vue"
              ),
          },
          {
            path: "/booked",
            name: "BookedBillboards",
            component: () =>
              import(
                /* webpackChunkName: "booked-billboards" */ "@/components/media_owner/BookedBillboards.vue"
              ),
          },
        ],
        meta: {
          resource: "billboards",
        },
      },
      {
        path: "/account-details",
        name: "AccountDetails",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "account-details" */ "@/pages/user/AccountDetails.vue"
          ),
        meta: {
          resource: "account-details",
        },
      },
      {
        path: "/settings",
        name: "UserSettings",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "user-settings" */ "@/pages/settings/UserSettings.vue"
          ),
        meta: {
          resource: "user-settings",
        },
      },
      {
        path: "/organization",
        name: "OrganizationSettings",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "organization-settings" */ "@/pages/settings/OrganizationSettings.vue"
          ),
        meta: {
          resource: "organization-settings",
        },
      },
      {
        path: "/brands",
        name: "Brands",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "brands" */ "@/pages/brands/TheBrand.vue"
          ),
        meta: {
          resource: "brands",
        },
      },
      {
        path: "/billboard-availability/:token",
        name: "BillboardAvailability",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "billboard-availability" */ "@/components/campaign/BillboardAvailability.vue"
          ),
        meta: {
          resource: "billboard-availability",
        },
      },
      {
        path: "/inspections",
        name: "Inspections",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "inspections" */ "@/pages/inspections/InspectionsMap.vue"
          ),
        meta: {
          resource: "inspections",
        },
      },
    ],
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "@/pages/auth/RegisterClient.vue"
      ),
    meta: {
      resource: "register",
    },
  },
  {
    path: "/set-new-password/:token",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "set-new-password" */ "@/pages/auth/ResetPassword.vue"
      ),
    meta: {
      resource: "set-new-password",
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "@/pages/auth/ForgotPassword.vue"
      ),
    meta: {
      resource: "forgot-password",
    },
  },
  {
    path: "/verify-account/:token",
    name: "VerifyAccount",
    component: () =>
      import(
        /* webpackChunkName: "verify-account" */ "@/pages/auth/VerifyAccount.vue"
      ),
    meta: {
      resource: "verify-account",
    },
  },
  {
    path: "/cancel-invitation/:invite_token",
    name: "CancelInvitation",
    component: () =>
      import(
        /*webChunkName: "cancel-invitation" */ "@/pages/user/RejectInvitation.vue"
      ),
    meta: {
      resource: "cancel-invitation",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/pages/auth/LoginClient.vue"),
    meta: {
      resource: "login",
    },
  },
  {
    path: "/forbidden",
    name: "forbidden",
    component: () =>
      import(/* webChunkName: "forbidden" */ "@/pages/ForbiddenPage.vue"),
    meta: {
      resource: "forbidden",
    },
  },
  {
    path: "*",
    component: () =>
      import(/* webpackChunkName: "notFound" */ "@/pages/NotFound.vue"),
    meta: {
      resource: "not-found",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
