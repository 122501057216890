import { handleHttpError, reportSuccess, baseUrl, getToken } from "./utils";

const axios = require("axios").default;

async function fetchAllCampaigns() {
  const token = await getToken();
  try {
    const response = await axios.get(`${baseUrl}/plans/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (err) {
    const error = err.response
      ? `${err.response.data.error.message}`
      : "An error occurred fetching Campaigns. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchCampaignList() {
  const token = await getToken();
  try {
    const response = await axios.get(`${baseUrl}/plans/list`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data.data;
  } catch (err) {
    const error = err.response
      ? `${err.response.data.error.message}`
      : "An error occurred fetching Campaigns. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchUserCampaigns(user_id) {
  const token = await getToken();
  try {
    const response = await axios.get(`${baseUrl}/plans/user/${user_id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data.data.plans;
  } catch (err) {
    const error = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred fetching Campaigns. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchAllMediaOwnerCampaigns(userId) {
  const token = await getToken();

  try {
    const response = await axios.get(
      `${baseUrl}/plans/media-owner/${userId}/all-plans`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data.data.plans;
  } catch (err) {
    const error = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred fetching All Media owner Campaigns. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchRunningMediaOwnerCampaigns(userId) {
  const token = await getToken();

  try {
    const response = await axios.get(
      `${baseUrl}/plans/media-owner/${userId}/running-plans`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data.data;
  } catch (err) {
    const error = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred fetching Running Campaigns. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchInvitedMediaOwnerCampaigns(userId) {
  const token = await getToken();

  try {
    const response = await axios.get(
      `${baseUrl}/plans/media-owner/${userId}/invited-plans`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data.data;
  } catch (err) {
    const error = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred fetching Invited Media owner Campaigns. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchBidedMediaOwnerCampaigns(userId) {
  const token = await getToken();

  try {
    const response = await axios.get(
      `${baseUrl}/plans/media-owner/${userId}/bided-plans`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data.data;
  } catch (err) {
    const error = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred fetching All Media owner Campaigns. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchCampaign(id) {
  const token = await getToken();
  try {
    const response = await axios.get(`${baseUrl}/plans/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data.plan;
  } catch (err) {
    const error = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred fetching Campaign. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchCampaignByToken(token) {
  try {
    const response = await axios.get(`${baseUrl}/plans/token/${token}`);
    return response.data.data.plan;
  } catch (err) {
    const error = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred fetching Campaign. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function exportPlanPPT(id) {
  const token = await getToken();
  try {
    const response = await axios.get(`${baseUrl}/plans/ppt/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status == 200) {
      return reportSuccess(
        "The campaign has been exported. You will receive it via email."
      );
    } else {
      return handleHttpError("Error exporting campaign");
    }
  } catch (err) {
    return handleHttpError("Error exporting campaign");
  }
}

async function addCampaign(payload) {
  const token = await getToken();
  let response;
  const { mode, data } = payload;
  try {
    if (mode === "brief") {
      response = await axios.post(`${baseUrl}/plans/brief`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } else if (mode === "map") {
      response = await axios.post(`${baseUrl}/plans/map`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
    }

    if (response.data.data.message) {
      const message = `Added campaign successfully`;
      reportSuccess(message);
      return true;
    }
  } catch (err) {
    const error = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred adding Campaign.Please try again or contact the System Admin";

    handleHttpError(error);
    return false;
  }
}
async function addBillboardToPlan(payload) {
  const token = await getToken();
  try {
    await axios.put(
      `${baseUrl}/plans/${payload.planId}/${payload.billboardId}`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const message = `Added Billboard successfully`;
    return reportSuccess(message);
  } catch (err) {
    const message = err.response.data
      ? `${err.response.data.error.message}`
      : "An error was encountered trying to upload the billboard. Please try again or Contact the System Admin";
    return handleHttpError(message);
  }
}

async function updateCampaign() {}

async function updateCampaignComment(payload) {
  try {
    await axios.put(`${baseUrl}/plans/token/${payload.token}`, { ...payload });
    return reportSuccess("Campaign Comment uploaded successfully");
  } catch (err) {
    const message = err.response.data
      ? `${err.response.data.error.message}`
      : "An error was encountered trying to submit the comment. Please try again or Contact the System Admin";
    return handleHttpError(message);
  }
}

async function deleteCampaign(id) {
  try {
    const token = await getToken();

    await axios.delete(`${baseUrl}/plans/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const message = `Campaign deleted successfully`;
    return reportSuccess(message);
  } catch (err) {
    const error = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred Deleting Campaign.Please try again or Contact the System Admin ";
    return handleHttpError(error);
  }
}

export {
  fetchCampaign,
  addCampaign,
  updateCampaign,
  deleteCampaign,
  exportPlanPPT,
  fetchAllCampaigns,
  fetchCampaignByToken,
  fetchCampaignList,
  fetchUserCampaigns,
  updateCampaignComment,
  addBillboardToPlan,
  fetchAllMediaOwnerCampaigns,
  fetchBidedMediaOwnerCampaigns,
  fetchRunningMediaOwnerCampaigns,
  fetchInvitedMediaOwnerCampaigns,
};
