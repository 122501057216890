import { getToken } from "@/helpers/utils";

export default async (to, from, next) => {
  const token = await getToken();

  if (!token) {
    next(`/login?returnUrl=${to.path}`);
    return;
  }
  next();
};
