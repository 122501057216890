import {
  fetchCampaign,
  addCampaign,
  updateCampaign,
  deleteCampaign,
  fetchUserCampaigns,
  fetchCampaignByToken,
  fetchAllCampaigns,
  fetchAllMediaOwnerCampaigns,
  fetchBidedMediaOwnerCampaigns,
  fetchRunningMediaOwnerCampaigns,
  fetchInvitedMediaOwnerCampaigns,
} from "@/helpers/campaigns";

export default {
  async fetchAllCampaigns() {
    const campaigns = await fetchAllCampaigns();
    return campaigns;
  },
  async fetchUserCampaigns({ commit }, payload) {
    const campaigns = await fetchUserCampaigns(payload);
    return commit("setCampaigns", campaigns);
  },
  async fetchCampaignByToken(_, token) {
    const campaign = await fetchCampaignByToken(token);
    return campaign;
  },
  async fetchCampaign(_, id) {
    const campaign = await fetchCampaign(id);
    return campaign;
  },

  async addCampaign(_, payLoad) {
    let response = await addCampaign(payLoad);
    return response;
  },

  async updateCampaign(_, payLoad) {
    await updateCampaign(payLoad);
  },

  async deleteCampaign(_, id) {
    await deleteCampaign(id);
  },

  async fetchAllMediaOwnerCampaigns(_, payload) {
    const campaigns = await fetchAllMediaOwnerCampaigns(payload);
    return campaigns;
  },

  async fetchBidedMediaOwnerCampaigns(_, payload) {
    const campaigns = await fetchBidedMediaOwnerCampaigns(payload);
    return campaigns;
  },

  async fetchRunningMediaOwnerCampaigns(_, payload) {
    const campaigns = await fetchRunningMediaOwnerCampaigns(payload);
    return campaigns;
  },

  async fetchInvitedMediaOwnerCampaigns(_, payload) {
    const campaigns = await fetchInvitedMediaOwnerCampaigns(payload);
    return campaigns;
  },
};
