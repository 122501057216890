<template>
  <v-app
    :style="{ 'background-color': $vuetify.theme.themes.light.background }"
  >
    <v-main>
      <v-fade-transition mode="in-out">
        <router-view></router-view>
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers("auth");

import SocketIOService from "./services/socketio.service.js";

export default {
  data() {
    return {
      show: false,
      socket: null,
    };
  },
  methods: {
    ...mapActions(["tryLogin"]),
  },

  computed: {},

  async created() {
    await this.tryLogin();

    await SocketIOService.setupSocketConnection();
    // await SocketIOService.listenToAllEvents();
  },

  async mounted() {
    this.show = true;
  },

  // before component is destroyed or tab closed
  beforeUnMount() {
    SocketIOService.disconnect();
  },
};
</script>
