export default {
  setRegions(state, payLoad) {
    return (state.regions = payLoad);
  },
  setRoadsData(state, payload) {
    return (state.roadsData = payload);
  },
  setRoadsList(state, payload) {
    return (state.roadsList = payload);
  },
};
