import {
  fetchAllRegions,
  fetchRoadsList,
  fetchRoadsData,
} from "@/helpers/regions";

export default {
  async fetchAllRegions({ commit }) {
    const regions = await fetchAllRegions();
    return commit("setRegions", regions);
  },
  async fetchRoadsData({ commit }, payload) {
    const roads = await fetchRoadsData(payload);
    return commit("setRoadsData", roads);
  },
  async fetchRoadsList({ commit }) {
    const roads = await fetchRoadsList();
    return commit("setRoadsList", roads);
  },
};
