import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
  namespaced: true,
  state() {
    return {
      campaigns: [],
      currentCampaign: {
        id: null,
        budget: null,
        duration: null,
        start_date: null,
        end_date: null,
        name: null,
        PlanComments: [],
        Candidates: [
          {
            negotiated_net_rate: null,
            status: null,
            availability_confirmation: null,
            CandidateComments: [],
            Billboard: {
              id: null,
              latitude: null,
              longitude: null,
              Images: [{ path: null }],
              MediaOwner: {
                User: {
                  Organization: {
                    fullName: null,
                  },
                  firstName: null,
                  lastName: null,
                },
              },
            },
          },
        ],

        Planner: {
          User: {
            Organization: {
              fullName: null,
            },
            firstName: null,
            lastName: null,
          },
        },
        Brief: {
          name: null,
          budget: null,
          BrandOwner: {
            Brand: {
              name: null,
              BrandCategory: {
                name: null,
              },
            },
          },
        },
      },
    };
  },
  mutations,
  getters,
  actions,
};
