import { io } from "socket.io-client";
import { getToken } from "../../src/helpers/utils";
import { sendNotification, getUserDetails } from "../helpers/utils";
import { Store } from "vuex";

class SocketIOService {
  socket;
  token;
  constructor() {
    /* TODO: document why this constructor is empty */
  }

  async setupSocketConnection() {
    this.token = await getToken();
    if (!this.token) return;

    this.socket = io(process.env.VUE_APP_SOCKET_ENDPOINT, {
      auth: { token: this.token },
    });
  }

  // can send message to other users
  sendMessage({ message, roomName }, cb) {
    if (this.socket) this.socket.emit("message", { message, roomName }, cb);
  }

  // listen to messages
  // eg on brief creation
  subscribeToMessages(cb) {
    if (!this.socket) return true;
    this.socket.on("message", (msg) => {
      return cb(null, msg);
    });
  }

  // listen to briefs
  listenToBriefs() {
    this.socket.on("brief_created", (brief_details) => {
      console.log("brief_details", brief_details);
    });
  }

  async listenToAllEvents() {
    if (!this.socket) return await this.setupSocketConnection();

    this.socket.onAny(async (_event, args) => {
      const user = await getUserDetails();
      const { message, receivers } = args;

      if (receivers.includes(user.userId)) {
        return sendNotification(message);
      }

      Store.dispatch("notifications/fetchAllUserNotifications", user.userId);
    });
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}

export default new SocketIOService();
