import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
  namespaced: true,
  state() {
    return {
      briefs: [],
      // currently handle one brief at a time
      briefsCart: {
        id: null,
        budget: 0,
        region: [],
        campaignType: null,
        sec: [],
        ageGroup: [],
        gender: [],
        start_date: null,
        end_date: null,
        description: null,
        consumer_insights: null,
        brand_objectives: null,
        media_objectives: null,
        preferred_medium_type: [],
        other_media_types: [],
        deadline: null,
        targetAgency: [],
        BrandOwner: {
          Marketer: {
            client: null,
            Organization: {
              fullName: null,
            },
          },
          Planner: {
            client: null,
            Organization: {
              fullName: null,
            },
          },
          Brand: {
            name: null,
            BrandCategory: {
              name: null,
              id: null,
            },
          },
        },
      },
      currentBrief: null,
      briefCampaigns: [],
    };
  },
  mutations,
  getters,
  actions,
};
