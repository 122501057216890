import {
  fetchBrandCompetitor,
  fetchBrandCategories,
  fetchUserBrands,
  fetchAllBrands,
  addBrand,
  fetchBrand,
  updateBrand,
  deleteBrand,
} from "@/helpers/brands";

export default {
  async addBrand(_, payload) {
    return await addBrand(payload);
  },
  async fetchBrand(_, payload) {
    return await fetchBrand(payload);
  },
  async updateBrand(_, payload) {
    return await updateBrand(payload);
  },
  async deleteBrand(_, payload) {
    return await deleteBrand(payload);
  },
  async fetchBrandCompetitor({ commit }, payload) {
    const competitors = await fetchBrandCompetitor(payload);
    commit("setBrandCompetitors", competitors);
  },
  async fetchBrandCategories() {
    return await fetchBrandCategories();
  },

  async fetchUserBrands({ commit }, payload) {
    const brands = await fetchUserBrands(payload);
    commit("setUserBrands", brands);
  },

  async fetchAllBrands({ commit }) {
    const brands = await fetchAllBrands();
    commit("setAllBrands", brands);
  },
};
