import {
  fetchAllMonitoring,
  fetchMonitoringByCandidate,
  fetchMonitoringByDate,
  fetchMonitoringByDates,
  fetchMonitoringByMonitor,
  fetchMonitoringByCampaign,
  monitorByOwner,
  fetchMonitoringByQuery,
} from "@/helpers/monitoring";

export default {
  async monitorByOwner(_, payLoad) {
    await monitorByOwner(payLoad);
  },

  async fetchMonitoringByQuery({ commit }, payLoad) {
    const monitorings = await fetchMonitoringByQuery(payLoad);
    return commit("setMonitoring", monitorings);
  },
  async fetchMonitoringByCampaign({ commit }, campaignId) {
    const monitorings = await fetchMonitoringByCampaign(campaignId);
    return commit("setMonitoring", monitorings);
  },

  async fetchAllMonitoring({ commit }) {
    const monitorings = await fetchAllMonitoring();
    return commit("setMonitoring", monitorings);
  },

  async fetchMonitoringByCandidate({ commit }, candidateId) {
    const monitorings = await fetchMonitoringByCandidate(candidateId);
    return commit("setMonitoring", monitorings);
  },

  async fetchMonitoringByDate({ commit }, date) {
    const monitorings = await fetchMonitoringByDate(date);
    return commit("setMonitoring", monitorings);
  },

  async fetchMonitoringByDates({ commit }, dates) {
    const monitorings = await fetchMonitoringByDates(dates);
    return commit("setMonitoring", monitorings);
  },

  async fetchMonitoringByMonitor({ commit }, monitorId) {
    const monitorings = await fetchMonitoringByMonitor(monitorId);
    return commit("setMonitoring", monitorings);
  },
};
